import React, { useRef, useEffect, useState, useContext, createContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { UserContext } from '../App';
import MainLayoutWrapper from './MainLayoutWrapper';
import { getLoginToken } from './../helpers/UserHelper';

export const MenskundeContext = createContext(null);

function MenskundeLayoutWrapper({ settings }) {
    const userContext = useContext(UserContext);
    const location = useLocation();
    const pageTransitionElRef = useRef(null);
    const [menskundeTopics, setMenskundeTopics] = useState([]);

    useEffect(() => {
        if (getLoginToken() === null || userContext.fetchHeaders === null || menskundeTopics.length > 0) return;

        try {
            fetch(settings.apiBaseUrl + 'menskunde/general', {
                method: 'POST',
                headers: userContext.fetchHeaders,
            })
                .then(result => result.json())
                .then(response => {
                    if (!response.success) return;
                    setMenskundeTopics(response.menskunde.topics);
                });
        } catch (error) {
            console.error(error);
        }
    }, [userContext.fetchHeaders, settings.apiBaseUrl, location.pathname, menskundeTopics.length]);

    const getTopic = function(topicSlug) {
        return menskundeTopics.find(topic => topic.slug === topicSlug) || null;
    };

    return (
        <MenskundeContext.Provider value={{
            topics: menskundeTopics,
            getTopic
        }}>
            <MainLayoutWrapper settings={settings}>
                <Outlet key={location.pathname} />
            </MainLayoutWrapper>

            <div className="page-transition" ref={pageTransitionElRef}>
                <div className="page-transition__item page-transition__item--l1"></div>
                <div className="page-transition__item page-transition__item--l2"></div>
                <div className="page-transition__item page-transition__item--r1"></div>
                <div className="page-transition__item page-transition__item--r2"></div>
            </div>
        </MenskundeContext.Provider>
    );
}

export default MenskundeLayoutWrapper;