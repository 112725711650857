import React from 'react';
import { useNavigate } from 'react-router-dom';

function VsPedagogiekListCard({ card, callback = null, showThumbnail = true, altBgColor = false }) {
    const navigate = useNavigate();

    function goToCard(card) {
        if (callback !== null) callback();
        navigate(`/vs-pedagogiek/kaart/${card.slug}`);
    }

    function getCardTitle() {
        return card.name;
    }

    function getCardSubtitle() {
        return card.lpb_type;
    }

    return (
        <button type="button" className={'list-card' + (!showThumbnail ? ' list-card--no-thumbnail' : '') + (!!altBgColor ? ' list-card--alt' : '')} onClick={() => goToCard(card)}>
            {showThumbnail && <>
                {!!card.featured_image.thumbnail && <div className="list-card__img-wrapper" style={{ backgroundImage: `url(${card.featured_image.thumbnail})` }}></div>}
                {!card.featured_image.thumbnail && <div className="list-card__img-wrapper list-card__img-wrapper--no-image"></div>}
            </>}

            <div className="list-card__content-wrapper">
                <span className="list-card__subtitle">{getCardSubtitle()}</span>
                <span className="list-card__title heading:3">{getCardTitle()}</span>
                <div className="list-card__excerpt default-styles"><p>{card.excerpt}</p></div>
                <div className="list-card__icon"></div>
            </div>
        </button>
    );
}

export default VsPedagogiekListCard;