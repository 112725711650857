import React from 'react';
import { useState, useEffect, createContext } from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import './assets/scss/main.scss';

import Page404 from './Page404/Page404';
import PageVakcurriculum from './PageVakcurriculum/PageVakcurriculum';
import PageLogin from './PageLogin/PageLogin';
import PageLogout from './PageLogout/PageLogout';
import PageVakcurriculumFormulier from './VakcurriculumFormulier/PageVakcurriculumFormulier';
import PageNewVakcurriculumCard from './VakcurriculumFormulier/PageNewVakcurriculumCard';
import PageEditVakcurriculumCard from './VakcurriculumFormulier/PageEditVakcurriculumCard';
import PagePrikbordTopics from './Prikbord/PagePrikbordTopics';
import PagePrikbordTopic from './Prikbord/PagePrikbordTopic';
import PrikbordWrapper from './Prikbord/PrikbordWrapper';
import MainLayoutWrapper from './components/MainLayoutWrapper';
import MyAccountLayout from './MyAccount/MyAccountLayout';
import MyAccountGeneral from './MyAccount/MyAccountGeneral';
import PageOverLeerplanboom from './PageOverLeerplanboom/PageOverLeerplanboom';
import PageKlas from './PageKlas/PageKlas';
import PageVak from './PageVak/PageVak';
import PageVakcurCard from './PageVakcurCard/PageVakcurCard';
import PageMenskunde from './Menskunde/PageMenskunde';
import { getCurrentUser, updateLocalUser, canCurrentUserDo, getUserPermissionValue, getLoginToken } from './helpers/UserHelper';
import VakcurLayoutWrapper from './components/VakcurLayoutWrapper';
import VakcurriculumFormWrapper from './VakcurriculumFormulier/components/VakcurriculumFormWrapper';
import FeedbackModule from './components/FeedbackModule';
import MenskundeLayoutWrapper from './components/MenskundeLayoutWrapper';
import PageMenskundeTopic from './Menskunde/PageMenskundeTopic';
import PageMenskundeCard from './Menskunde/PageMenskundeCard';
import VsPedagogiekLayoutWrapper from './components/VsPedagogiekLayoutWrapper';
import PageVsPedagogiek from './VsPedagogiek/PageVsPedagogiek';
import PageVsPedagogiekTopic from './VsPedagogiek/PageVsPedagogiekTopic';
import PageVsPedagogiekCard from './VsPedagogiek/PageVsPedagogiekCard';
import OntwikkelingsmomentLayoutWrapper from './components/OntwikkelingsmomentLayoutWrapper';
import PageOntwikkelingsmoment from './Ontwikkelingsmoment/PageOntwikkelingsmoment';
import PageOntwikkelingsmomentCard from './Ontwikkelingsmoment/PageOntwikkelingsmomentCard';
// import PageTmpHome from './PageTmpHome/PageTmpHome';

export const UserContext = createContext(null);

function App() {
    const _currentUser = getCurrentUser();

    const [isLoggedIn, setIsLoggedIn] = useState(_currentUser !== null && _currentUser.loginToken !== null);
    const [fetchHeaders, setFetchHeaders] = useState(null);

    const apiBase = getApiBaseUrl();
    const settings = {
        apiBaseUrl: apiBase + 'lpb/v1/',

        authentication: {
            apiTokenUrl: apiBase + 'jwt-auth/v1/token',
            apiTokenValidateUrl: apiBase + 'jwt-auth/v1/token/validate'
        }
    };

    useEffect(() => {
        const token = getLoginToken();
        setFetchHeaders(token !== null ? { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } : null);
    }, [isLoggedIn]);

    useEffect(() => {
        if (getLoginToken() === null || fetchHeaders === null) return;

        try {
            // Validate the login token
            fetch(settings.authentication.apiTokenValidateUrl, {
                method: 'POST',
                headers: fetchHeaders,
            })
                .then(result => result.json())
                .then(response => {
                    console.log(response.data.status === 200 ? 'Login is valid' : 'Login is invalid');
                    setIsLoggedIn(response.data.status === 200);
                });
        } catch (error) {
            console.error(error);
            setIsLoggedIn(false);
        }
    }, [fetchHeaders, settings.authentication.apiTokenValidateUrl]);

    function getApiBaseUrl() {
        switch (process.env.REACT_APP_ENVIRONMENT) {
            case 'development': return 'https://leerplanboom-backend.test/wp-json/';
            case 'staging': return 'https://encode-projects.dev/leerplanboom-v2/backend/wp-json/';
            default: return 'https://leerplanboom.kgcnijmegen.nl/backend/wp-json/';
        }
    }

    function getLoginUrl() {
        switch (process.env.REACT_APP_ENVIRONMENT) {
            case 'development': return 'https://leerplanboom-backend.test/wp-admin/?login-frontend=1';
            case 'staging': return 'https://encode-projects.dev/leerplanboom-v2/backend/beheer/?login-frontend=1';
            default: return 'https://leerplanboom.kgcnijmegen.nl/backend/beheer/?login-frontend=1';
        }
    }

    function getLogoutUrl() {
        switch (process.env.REACT_APP_ENVIRONMENT) {
            case 'development': return 'https://local.test/dev-leerplanboom-v2/leerplanboom-backend/wp-login.php?action=logout';
            case 'staging': return 'https://encode-projects.dev/leerplanboom-v2/backend/beheer/?action=logout';
            default: return 'https://leerplanboom.kgcnijmegen.nl/backend/beheer/?action=logout';
        }
    }

    async function login(username, password) {
        return new Promise(async resolve => {
            const response = await fetch(settings.authentication.apiTokenUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password })
            });

            const jsonResult = await response.json();

            if (typeof jsonResult.token !== 'undefined' && jsonResult.token) {
                localStorage.setItem('lpb_user', JSON.stringify({
                    loginToken: jsonResult.token,
                    id: jsonResult.user_id,
                    displayName: jsonResult.user_display_name,
                    avatar: jsonResult.user_avatar,
                    permissions: jsonResult.user_permissions,
                    firstName: jsonResult.user_firstname,
                    lastName: jsonResult.user_lastname
                }));

                setIsLoggedIn(true);
                resolve(true);
            }
            else {
                localStorage.removeItem('lpb_user');

                setIsLoggedIn(false);
                resolve(false);
            }
        });
    }

    async function sendPasswordResetEmail(email) {
        return new Promise(async resolve => {
            const response = await fetch(settings.apiBaseUrl + 'request-password-reset', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_email: email })
            });

            const jsonResult = await response.json();

            resolve(jsonResult);
        });
    }

    function logout() {
        localStorage.removeItem('lpb_user');
        setIsLoggedIn(false);
    }

    const routes = [
        {
            path: '/', element: <MainLayoutWrapper settings={settings} />, children: [
                // General
                { path: 'over-de-leerplanboom', element: <PageOverLeerplanboom settings={settings} /> },

                // Prikbord
                {
                    path: 'prikbord',
                    element: <PrikbordWrapper settings={settings} />,
                    children: [
                        { path: '', element: <PagePrikbordTopics settings={settings} /> },
                        { path: 'topics', element: <Navigate to="/prikbord" /> },
                        { path: 'topics/:topicSlug', element: <PagePrikbordTopic settings={settings} /> }
                    ]
                },

                // My account
                {
                    path: 'mijn-account',
                    element: <MyAccountLayout />,
                    children: [
                        { path: '', element: <MyAccountGeneral settings={settings} updateLocalUser={updateLocalUser} /> }
                    ]
                }
            ]
        },

        // Vakcurriculum
        {
            path: '/vakcurriculum', element: <VakcurLayoutWrapper settings={settings} />, children: [
                { path: '', element: <PageVakcurriculum /> },
                { path: 'klas/:klas', element: <PageKlas /> },
                { path: 'vak/:vak', element: <PageVak /> },
                { path: 'kaart/:slug', element: <PageVakcurCard settings={settings} /> },
            ]
        },

        { path: '/login', element: <PageLogin login={login} sendPasswordResetEmail={sendPasswordResetEmail} /> },
        { path: '/logout', element: <PageLogout /> },

        // Vakcurrculum formulier
        { path: '/vakcurriculum-formulier', element: <VakcurriculumFormWrapper />, children: [
                { path: '', element: <PageVakcurriculumFormulier settings={settings} /> },
                { path: 'new', element: <PageNewVakcurriculumCard settings={settings} /> },
                { path: 'edit/:cardId', element: <PageEditVakcurriculumCard settings={settings} /> },
            ]
        },

        // Menskunde
        {
            path: '/menskunde', element: <MenskundeLayoutWrapper settings={settings} />, children: [
                { path: '', element: <PageMenskunde settings={settings} /> },
                { path: 'topics/:topicSlug', element: <PageMenskundeTopic /> },
                { path: 'kaart/:slug', element: <PageMenskundeCard settings={settings} /> }
            ]
        },

        // VS-Pedagogiek
        {
            path: '/vs-pedagogiek', element: <VsPedagogiekLayoutWrapper settings={settings} />, children: [
                { path: '', element: <PageVsPedagogiek settings={settings} /> },
                { path: 'topics/:topicSlug', element: <PageVsPedagogiekTopic /> },
                { path: 'kaart/:slug', element: <PageVsPedagogiekCard settings={settings} /> }
            ]
        },

        // Ontwikkelingsmoment
        {
            path: '/ontwikkelingsmoment', element: <OntwikkelingsmomentLayoutWrapper settings={settings} />, children: [
                { path: '', element: <PageOntwikkelingsmoment settings={settings} /> },
                { path: 'kaart/:slug', element: <PageOntwikkelingsmomentCard settings={settings} /> }
            ]
        },

        // Other
        { path: '*', element: <Page404 /> }
    ];

    routes[0].children = [{ path: '', element: <Navigate to="/vakcurriculum" /> }, ...routes[0].children];

    const router = createBrowserRouter(routes, {
        basename: process.env.REACT_APP_ENVIRONMENT === 'staging' ? '/leerplanboom-v2' : '/'
    });

    return (
        <UserContext.Provider value={{ fetchHeaders, isLoggedIn, getCurrentUser, logout, canCurrentUserDo, getUserPermissionValue, getLoginUrl, getLogoutUrl }}>
            <RouterProvider router={router} />
            <FeedbackModule settings={settings} />
        </UserContext.Provider>
    );
}

export default App;