import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import TopicPost from './components/TopicPost';

import iconBtnArrow from './../assets/images/icon-btn-arrow.svg';

import { PrikbordContext } from './PrikbordWrapper';
import NewTopicPostBox from './components/NewTopicPostBox';

import { UserContext } from '../App';
import CustomSelect from '../components/CustomSelect';

function PagePrikbordTopic({ settings }) {
    const userContext = useContext(UserContext);

    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);

    const { topicSlug } = useParams();
    const [topic, setTopic] = useState(null);
    const { topics } = useContext(PrikbordContext);
    const topicEl = useRef(null);

    const [mostRecentPost, setMostRecentPost] = useState(null);
    const [mostPopularPost, setMostPopularPost] = useState(null);

    const [currentPageCount, setCurrentPageCount] = useState(1);
    const [maxNumPages, setMaxNumPages] = useState(1);
    const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);

    const userTopics = userContext.getUserPermissionValue('allowed_prikbord_topic_slugs') || [];
    const canUserCreateNewTopic = topic ? (!topic.is_parent && userTopics.includes(topicSlug)) : false;

    useEffect(() => {
        if (userContext.fetchHeaders === null || !topicSlug) return;

        setIsLoading(true);

        // Get the topic
        fetch(settings.apiBaseUrl + 'prikbord/get-topic', {
            method: 'POST',
            headers: userContext.fetchHeaders,
            body: JSON.stringify({ topicSlug: topicSlug })
        })
            .then(response => response.json())
            .then(result => {
                setIsLoading(false);

                if (result.success) {
                    setTopic(result.topic);
                    setMostRecentPost(result.most_recent_post || null);
                    setMostPopularPost(result.most_popular_post || null);
                    setMaxNumPages(result.max_num_pages);
                }
                else {
                    if (result.message === 'invalid-user-token') userContext.logout();
                    else alert('Oeps, er ging iets fout bij het laden van de pagina. Probeer het nogmaals.');
                }
            });
    }, [userContext, settings.apiBaseUrl, topicSlug]);

    useEffect(() => {
        if (!topicEl.current || !location.hash) return;

        const el = topicEl.current.querySelector('#topic-post_' + location.hash.replace('#post-', ''))
        if (!el) return;

        const bounds = el.getBoundingClientRect();

        window.scrollTo({
            top: bounds.top + window.scrollY - 200,
            behavior: 'smooth'
        });

        window.history.pushState('', document.title, window.location.pathname + window.location.search);
    }, [topic, location.hash]);

    function loadNextPage() {
        const nextPageNumber = currentPageCount + 1;

        setIsLoadingNextPage(true);

        fetch(settings.apiBaseUrl + 'prikbord/get-topic', {
            method: 'POST',
            headers: userContext.fetchHeaders,
            body: JSON.stringify({ topicSlug: topicSlug, pageNumber: nextPageNumber })
        })
            .then(response => response.json())
            .then(result => {
                setIsLoadingNextPage(false);

                if (result.success) {
                    setMaxNumPages(result.max_num_pages);
                    setCurrentPageCount(nextPageNumber);

                    const topicClone = JSON.parse(JSON.stringify(topic));
                    topicClone.posts = [...topicClone.posts, ...result.topic.posts];

                    setTopic(topicClone);
                }
                else {
                    if (result.message === 'invalid-user-token') userContext.logout();
                    else alert('Oeps, er ging iets fout bij het laden van de pagina. Probeer het nogmaals.');
                }
            });
    }

    function setTopicPost(post, deleteFromState = false) {
        const topicClone = JSON.parse(JSON.stringify(topic));

        if (deleteFromState) topicClone.posts = topicClone.posts.filter(p => p.id !== post.id);
        else topicClone.posts = topicClone.posts.map(p => p.id === post.id ? post : p)

        setTopic(topicClone);
    }

    const topicSelectOptions = topics !== null ? topics.map(t => { return { title: t.name, slug: t.slug } }) : [];
    const topicSelectOptionIndex = topic !== null ? (topicSelectOptions.findIndex(o => o.slug === (topic.is_parent ? topic.slug : topic.parent.slug))) : 0;

    function setSelectedOptionSlug(slug) {
        navigate('/prikbord/topics/' + slug);
    }

    return (<>
        <div className="top-spacer"></div>

        {!isLoading && topic !== null ?
            <div className="topic" ref={topicEl}>
                <div className="topic__left">
                    <div className="topic__left-btn-wrapper">
                        <Link className="btn btn--arrow btn--arrow-reversed" to="/prikbord">Alle onderwerpen<span style={{ backgroundImage: `url(${iconBtnArrow})` }}></span></Link>
                    </div>

                    {mostRecentPost !== null &&
                        <div className="widget">
                            <span className="widget__subject">Recent</span>

                            <div className="widget__post-preview">
                                <Link to={'/prikbord/topics/' + mostRecentPost.topic_slug + '/#post-' + mostRecentPost.id}>
                                    <span className="widget__post-preview__title heading:3">{mostRecentPost.name}</span>
                                    {mostRecentPost.followers.length > 1 && <span className="widget__post-preview__followers">{mostRecentPost.followers.length} gebruikers volgen dit bericht</span>}
                                </Link>
                            </div>
                        </div>
                    }

                    {mostPopularPost !== null &&
                        <div className="widget">
                            <span className="widget__subject">Populair</span>

                            <div className="widget__post-preview">
                                <Link to={'/prikbord/topics/' + mostPopularPost.topic_slug + '/#post-' + mostPopularPost.id}>
                                    <span className="widget__post-preview__title heading:3">{mostPopularPost.name}</span>
                                </Link>
                            </div>
                        </div>
                    }
                </div>

                <div className="topic__main">
                    <div className="topic__main topic__main--no-border">
                        <div className="topic__tag-row topic__tag-row--top-margin">
                            {!!topicSelectOptions && !!topicSelectOptions.length && <div className="topic-select-wrapper"><CustomSelect placeholderText="Kies een onderwerp" options={topicSelectOptions} selectedOptionSlug={topicSelectOptionIndex > -1 ? topicSelectOptions[topicSelectOptionIndex].slug : topicSelectOptions[0].slug} setSelectedOptionSlug={setSelectedOptionSlug} btnClasses="btn--mustard" compact={true} /></div>}
                            {!topic.is_parent && !!topic.parent && <span className="tag tag--alt">{topic.name} <Link to={'/prikbord/topics/' + topic.parent.slug} className="tag__close-btn">Sluiten</Link></span>}
                        </div>
                    </div>

                    { /* We need to check if we are allowed to post in this topic */ }
                    {canUserCreateNewTopic && <NewTopicPostBox topic={topic} setTopic={setTopic} settings={settings} />}

                    {topic.posts.length > 0 ? <>
                        {topic.posts.map(post => {
                            return <TopicPost key={'topic-post-' + post.id} post={post} settings={settings} setTopicPost={setTopicPost} topic={topic} setTopic={setTopic} />
                        })}
                    </> :
                        <div className="topic__no-posts">Er zijn nog geen berichten geplaatst in dit onderwerp.</div>
                    }

                    {currentPageCount < maxNumPages && <div className="topic__load-more-wrapper"><button disabled={isLoadingNextPage} type="button" className="btn btn--alt btn--mustard" onClick={loadNextPage}>{isLoadingNextPage ? 'Komt eraan..' : 'Meer berichten laden'}</button></div>}
                </div>

                <div className="topic__right">
                    {((topic.is_parent && topic.children.length > 0) || (topic.parent && topic.parent.children.length > 0)) &&
                        <div className="widget widget--alt">
                            <span className="widget__subject">Subonderwerpen</span>

                            <ul className="widget__topic-list">
                                {(topic.is_parent ? topic : topic.parent).children.map(t => {
                                    return <li key={'topic-' + t.term_id} className={t.slug === topic.slug ? ' current' : ''}><Link className="btn" to={'/prikbord/topics/' + t.slug}>{t.name}</Link></li>
                                })}
                            </ul>
                        </div>
                    }
                </div>
            </div>
            :
            <div className="loading-msg">Laden..</div>
        }
    </>);
}

export default PagePrikbordTopic;