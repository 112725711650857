import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';

import TopBar from './TopBar';
import BottomLogo from './BottomLogo';
import { UserContext } from '../App';

function MainLayoutWrapper({ settings }) {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();

    const showBottomLogo =
        ! location.pathname.startsWith('/over-de-leerplanboom') &&
        ! location.pathname.startsWith('/mijn-account') &&
        ! location.pathname.startsWith('/vakcurriculum/kaart') &&
        ! location.pathname.startsWith('/vakcurriculum/klas') &&
        ! location.pathname.startsWith('/menskunde/kaart') &&
        ! location.pathname.startsWith('/vs-pedagogiek/kaart') &&
        ! location.pathname.startsWith('/ontwikkelingsmoment/kaart');

    useEffect(() => {
        if (!userContext.isLoggedIn) return navigate(`/login?redirect=${encodeURIComponent(window.location.href)}`);
    }, [userContext, navigate]);

    return (<>
        { userContext.isLoggedIn &&
            <main className="main">
                <TopBar settings={settings} showBackground={location.pathname.startsWith('/prikbord/topics/')} />
                <Outlet />
                { !!showBottomLogo && <BottomLogo /> }
            </main>
        }
    </>);
}

export default MainLayoutWrapper;