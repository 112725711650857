import React, { useEffect, useCallback } from 'react';
import VakcurListCard from './VakcurListCard';
import MenskundeListCard from './MenskundeListCard';
import VsPedagogiekListCard from './VsPedagogiekListCard';

function CardSelectModal({ show, setShow, cards }) {
    const keyUp = useCallback((e => {
        if (e.key.toLowerCase() === 'escape') setShow(false);
    }), [setShow]);

    const renderSwitch = (card) => {
        switch(card.lpb_type) {
            case 'vakcurriculum' : return (<VakcurListCard card={card} callback={() => setShow(false)} />);
            case 'vs-pedagogiek' : return (<VsPedagogiekListCard card={card} callback={() => setShow(false)} />);
            default : return (<MenskundeListCard card={card} callback={() => setShow(false)} />);
        }
    }

    useEffect(() => {
        window.addEventListener('keyup', keyUp);
        return () => window.removeEventListener('keyup', keyUp);
    }, [keyUp]);

    return (
        <div className={'card-select-modal' + (show ? ' open' : '')}>
            <div className="card-select-modal__bg" onClick={() => setShow(false)}></div>

            <div className="card-select-modal__inner">
                <button type="button" className="card-select-modal__close-btn" onClick={() => setShow(false)}>Sluiten</button>

                <ul className="card-select-modal__cards">
                    {cards.map(card =>
                        <li key={Math.random() + Math.random() + ''}>
                            { renderSwitch(card) }
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default CardSelectModal;