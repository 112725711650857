import React, { useState, useCallback, useEffect, useContext } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import TopBarUserNotifications from './TopBarUserNotifications';
import MainMenu from './MainMenu';
import CustomSelect from './CustomSelect';
import { UserContext } from '../App';
import { VakcurContext } from './VakcurLayoutWrapper';

function TopBar({ settings, showBackground = false }) {
    const userContext = useContext(UserContext);
    const vakcurContext = useContext(VakcurContext);

    const navigate = useNavigate();
    const location = useLocation();

    const [notifications, setNotifications] = useState([]);
    const [showMainMenu, setShowMainMenu] = useState(false);

    const userSelectedAFilter = vakcurContext ? !!vakcurContext.activeFilters.klas || !!vakcurContext.activeFilters.vak : false;
    const isOnVakcurHome = location.pathname === '/vakcurriculum' || location.pathname === '/vakcurriculum/';
    const isOnVakcur = location.pathname.startsWith('/vakcurriculum');
    const isOnMenskunde = location.pathname.startsWith('/menskunde');
    const isOnVsPedagogiek = location.pathname.startsWith('/vs-pedagogiek');
    // const isOnMethodischDidactisch = location.pathname.startsWith('/methodisch-didactisch');
    const isOnOntwikkeling = location.pathname.startsWith('/ontwikkeling');

    const markNotificationAsRead = useCallback(notificationId => {
        try {
            fetch(settings.apiBaseUrl + 'mark-notification-as-read', {
                method: 'POST',
                headers: userContext.fetchHeaders,
                body: JSON.stringify({ notificationId: notificationId })
            })
                .then(result => result.json())
                .then(response => {
                    if (response.success) setNotifications(response.notifications);
                    else console.error('Error updating notification as read', response);
                })
                ;
        } catch (error) {
            console.error('Error updating notification as read', error);
        }
    }, [userContext.fetchHeaders, settings.apiBaseUrl]);

    const updateNotifications = useCallback(() => {
        if (!userContext.fetchHeaders) return;

        try {
            fetch(settings.apiBaseUrl + 'get-my-notifications', {
                method: 'POST',
                headers: userContext.fetchHeaders,
            })
                .then(result => result.json())
                .then(response => {
                    if (response.success) {
                        setNotifications(response.notifications);

                        const userData = JSON.parse(localStorage.getItem('lpb_user'));
                        localStorage.setItem('lpb_user', JSON.stringify({ ...userData, permissions: response.user_permissions }));
                    }
                    else console.error('Error requesting notifications', response);
                });
        } catch (error) {
            console.error('Error requesting notifications', error);
        }
    }, [userContext.fetchHeaders, settings.apiBaseUrl]);

    function clickHomeBtn() {
        if (location.pathname !== '/vakcurriculum') {
            if (vakcurContext) return vakcurContext.helperFunctions.nav('/vakcurriculum')
            return navigate('/vakcurriculum');
        }
    }

    function clickMenskundeBtn() {
        return navigate('/menskunde');
    }

    function clickVsPedagogiekBtn() {
        return navigate('/vs-pedagogiek');
    }

    // function clickMethodischDidactischBtn() {
    //     alert('Implement');
    // }

    function clickOntwikkelingBtn() {
        return navigate('/ontwikkelingsmoment');
    }

    function navigateToView() {
        // When only a klas and no vak is selected
        if (!!vakcurContext.activeFilters.klas && !vakcurContext.activeFilters.vak) {
            return vakcurContext.helperFunctions.nav(`/vakcurriculum/klas/${vakcurContext.activeFilters.klas}`);
        }

        // When only a vak and no klas is selected
        else if (!!vakcurContext.activeFilters.vak) {
            return vakcurContext.helperFunctions.nav(`/vakcurriculum/vak/${vakcurContext.activeFilters.vak}`);
        }

        // Need to select more filters first
        else {
            alert('Selecteer eerst een klas en/of vak om door te gaan.');
        }
    }

    useEffect(() => {
        updateNotifications();

        // Update the notifications every 30 seconds
        const interval = setInterval(updateNotifications, 30 * 1000);
        return () => clearInterval(interval);
    }, [updateNotifications]);

    // Build the option arrays
    const klasOptions = vakcurContext ? vakcurContext.klassen.map(klas => { return { title: klas.human_name, slug: klas.slug } }) : null;
    if (vakcurContext) klasOptions.unshift({ title: 'Alle klassen', slug: null });

    const vakOptions = vakcurContext ? vakcurContext.vakken.map(vak => { return { title: vak.name, slug: vak.slug } }) : null;
    if (vakcurContext) vakOptions.unshift({ title: 'Alle vakken', slug: null });

    const trajectOptions = vakcurContext ? vakcurContext.trajecten.map(traject => { return { title: traject.name, slug: traject.slug } }) : null;
    if (vakcurContext) trajectOptions.unshift({ title: 'Alle trajecten', slug: null });

    if (!userContext.isLoggedIn) return null;

    return (<>
        <nav className={'top-bar' + (showBackground ? ' top-bar--bg' : '')}>
            <div className="top-bar__inner">
                <ul className="top-bar__areas">
                    <li><button className={'btn' + (isOnVakcur ? ' btn--mustard' : '')} onClick={clickHomeBtn}>Vakcurriculum</button></li>

                    { (process.env.REACT_APP_ENVIRONMENT === 'development' || process.env.REACT_APP_ENVIRONMENT === 'staging') && <>
                        <li><button className={'btn' + (isOnMenskunde ? ' btn--coral' : '')} onClick={clickMenskundeBtn}>Menskunde</button></li>
                        <li><button className={'btn' + (isOnVsPedagogiek ? ' btn--blue' : '')} onClick={clickVsPedagogiekBtn}>VS-Pedagogiek</button></li>
                        {/* <li><button className={'btn' + (isOnMethodischDidactisch ? ' btn--mustard' : '')} onClick={clickMethodischDidactischBtn}>Methodisch-didactisch</button></li> */}
                        <li><button className={'btn' + (isOnOntwikkeling ? ' btn--green' : '')} onClick={clickOntwikkelingBtn}>Ontwikkeling</button></li>
                    </> }
                </ul>

                <div className="top-bar__right">
                    <div className="top-bar__search-wrapper">
                        <button type="button" className="top-bar__search-btn" onClick={() => vakcurContext.helperFunctions.setShowSearchOverlay(true)}>Zoeken</button>
                    </div>

                    <TopBarUserNotifications notifications={notifications} markNotificationAsRead={markNotificationAsRead} />

                    <button type="button" className="top-bar__my-profile-btn" onClick={() => navigate('/mijn-account')}>
                        <div style={{ backgroundImage: `url(${userContext.getCurrentUser().avatar})` }}></div>
                    </button>

                    <Link to="/prikbord" className={'btn' + (location.pathname.startsWith('/prikbord') ? ' btn--active btn--no-interaction' : '')}>Prikbord</Link>

                    <button type="button" className="top-bar__🍔" onClick={() => setShowMainMenu(true)}>Menu</button>
                </div>
            </div>

            {(isOnVakcur && !!vakcurContext && !!vakcurContext.klassen.length) && <>
                <div className="top-bar__inner top-bar__inner--second">
                    <ul className="top-bar__areas">
                        {!!vakcurContext &&
                            <ul className={'top-bar__expanding-filters show'}>
                                {!!vakcurContext.klassen.length && <>
                                    <li><CustomSelect placeholderText="Klas" selectedOptionSlug={vakcurContext.activeFilters.klas} setSelectedOptionSlug={vakcurContext.helperFunctions.setKlasFilter} options={klasOptions} /></li>
                                    <li><CustomSelect placeholderText="Vak" selectedOptionSlug={vakcurContext.activeFilters.vak} setSelectedOptionSlug={vakcurContext.helperFunctions.setVakFilter} options={vakOptions} /></li>
                                    <li><CustomSelect placeholderText="Traject" selectedOptionSlug={vakcurContext.activeFilters.traject} setSelectedOptionSlug={vakcurContext.helperFunctions.setTrajectFilter} options={trajectOptions} /></li>
                                    {!isOnVakcurHome && <li><CustomSelect placeholderText="Lestype" selectedOptionSlug={vakcurContext.activeFilters.lestype} setSelectedOptionSlug={vakcurContext.helperFunctions.setLestypeFilter} options={vakcurContext.lestypen.map(lestype => { return { title: lestype.name, slug: lestype.slug } })} /></li>}
                                    {isOnVakcurHome && userSelectedAFilter && <li><button className="custom-select__go-btn arrow-btn" onClick={navigateToView}>Ga!</button></li>}
                                </>}
                            </ul>
                        }
                    </ul>
                </div>
            </>}
        </nav>

        <MainMenu showMenu={showMainMenu} setShowMainMenu={setShowMainMenu} />
    </>);
}

export default TopBar;